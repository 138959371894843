.vendor-status-container {
    border: 2px solid blue;
    height: fit-content;
    width: 50%; 
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    text-align: center;
    border-radius: 10px;
}

.vendor-details-table {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
  .table-alignment {
    border-collapse: collapse;
    width: 50%; 
    margin-top: 10px;
  }
  
  th.header-cell {
    border-top: none;
    border-left: none;
    border-right: none;
    text-align: center;
  }
  
  th, td {
    border: 1px solid #ddd; /* Border color */
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2; /* Header background color */
  }

  .table-content-label {
    font-size: 0.8rem;
    font-weight: bold;
  }

  .table-content-values {
    font-size: 0.8rem;
    font-weight: 600;
  }
  

  /* Loading Details*/
  .spinner-container {
    height: 50px;
    width: 180px;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px; 
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    display: inline-block;
}

.loading-message {
    position: fixed;
    border-radius: 5px;
    padding-left: 50px;
    font-weight: bold;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.login-patil-icons {
    background-color: transparent;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .blur {
    filter: blur(5px); 
    pointer-events: none; 
  }